import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {upload} from '../../api';
import {isImage} from '../../tools/functions';

import ProgressBar from './ProgressBar';

const uploadingStates = {
    default: 0,
    uploading: 1,
    uploaded: 2,
    cancelled: 0,
    error: -1,
}
function SimpleFileUpload({onChange, value, upload_endpoint, inputProps}) {
    const [uploadingState, setUploadingState] = useState(uploadingStates.default);
    const  [uploadProgress, setUploadingProgress]  = useState({
        percentage: 0,
        size: 0,
        uploaded: 0,
    })
    // const [imageDataUri, setImageDataUri] = useState(null)

    const changed = (e) => {
        // var reader = new FileReader();
        // reader.onload = (e) => {
        //     setImageDataUri(e.target.result)
        // };
        // reader.readAsDataURL(e.target.files[0]);
        const form = new FormData();
        form.append("upload", e.target.files[0]);
        setUploadingState(uploadingStates.uploading);
        upload(upload_endpoint, form, setUploadingProgress).then((data) => {
                setUploadingState(uploadingStates.uploaded)
                if (onChange) {
                    onChange(data);
                }
                setUploadingProgress({
                    percentage: 0,
                    size: 0,
                    uploaded: 0,
                })
            }).catch((e) => {
                setUploadingState(uploadingStates.error)
        })
    }

    const onDelete = () => {
        if (onChange) {
            onChange({});
        }
        setUploadingState(uploadingStates.default)
    }
    const {url, width, height, originalname} = value || {}
     if (uploadingState === uploadingStates.uploading) {
            return <RenderUploading percentage={uploadProgress.percentage} size={uploadProgress.size} uploaded={uploadProgress.uploaded}/>
    } else if (url) {
        return <RenderUploaded uri={url} onDelete={onDelete}
                               originalname={originalname}
                               imgWidth={width}
                               imgHeight={height}/>
    } else {
        return <input {...inputProps || {}} type={"file"} className={"form-control"}
                      onChange={changed}/>
    }
}

function RenderUploaded({uri, onDelete, imgWidth, imgHeight, originalname}) {

    return <div>
        <a title={"Open in a new tab"} target={"_blank"} href={uri}>
            {
                isImage(uri) ?
                    <img src={uri}
                         style={{
                             objectFit:'contain',
                             maxWidth:'100%',
                             maxHeight:240,
                             width: imgHeight !== 'auto' ? 'auto' : imgWidth
                          }}
                         alt={"Uploaded file"} className={"bg-light rounded shadow-sm"}/>
                    : originalname
            }
        </a>
        <br/>
        <button type={"button"} className={"btn btn-link text-danger"} onClick={onDelete}>Delete</button>
    </div>
}

function RenderUploading({percentage, size, uploaded}) {
    return <div className={"text-center"}>
        <ProgressBar current={uploaded} text={`${percentage}%`} max={size} height={38}/>
    </div>

}

SimpleFileUpload.propTypes = {
    onChange: PropTypes.func.isRequired,
    location: PropTypes.string,
    upload_endpoint: PropTypes.string,
    width: PropTypes.any,
    height: PropTypes.any
}
SimpleFileUpload.defaultProps = {
    upload_endpoint: '/files/upload',
    width: 200,
    height: 'auto'
}

export default SimpleFileUpload;
