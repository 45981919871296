import CurrencyNumber from '../../../cmp/CurrencyNumber';
import RenderInterval from './RenderInterval';
import Button from '../../../cmp/form/Button';
import {FormattedMessage} from 'react-intl';
import ShopifyBuyButton from './ShopifyBuyButton';
import ShopifyTestForPurchase from './ShopifyTestForPurchase';

export default function PlanCard({
            cost, plan_title, num_plans, plan_id, plan_type, perks, description,
            interval_count, interval_unit,
            selling_plan_id,
            upsells,
            onChoose, loading, isChosen, currency,
            storefront_access_token,
            product,
                  }) {
    return <div className={"card flex-fill"}>
      <div className="flex-fill">
        <div className="p-3">
            <h5 className="card-title text-center">{plan_title}</h5>
            <div className={"fs-2 text-center"}>
                <CurrencyNumber currency={currency} value={cost}/>
                {plan_type === "recurring" && <span className={"text-muted"} style={{fontSize: '1rem'}}>
                        /<RenderInterval interval_count={interval_count} interval_unit={interval_unit}/>
                    </span>
                }
            </div>
          {
            plan_type === "one_time_purchase" ? <><div className={"text-center"}>
                <ShopifyBuyButton
                    upsells={upsells}
                    selling_plan_id={selling_plan_id}
                    plan_id={plan_id} product={product} storefront_token={storefront_access_token} />
              </div>
                <div>
                  <ShopifyTestForPurchase plan_id={plan_id} plan_title={plan_title} />
                </div>
            </> : null
          }
        </div>
        {
            perks && perks.split("\n").length > 0 && <ul className={"list-group list-group-flush"}>
                {
                    perks && perks.split("\n").filter((item) => item)
                        .map((item) => <li key={item} className={"text-center list-group-item"}>{item}</li>)
                }
            </ul>
        }
        {
            description && <div className="card-body" dangerouslySetInnerHTML={{__html: description}}/>
        }
        </div>

      {
            num_plans > 1 &&  plan_type !== "one_time_purchase" ?
            <div className="card-footer text-center">
                <Button
                    disabled={loading && !isChosen}
                    loading={loading && isChosen}
                    className={`btn btn-primary`} onClick={onChoose}>
                    <FormattedMessage id={"Choose"}/>
                </Button>
            </div> : null
        }
    </div>
}
