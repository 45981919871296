import React from 'react';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        themePrimaryColor: state.settings.themePrimaryColor
    };
}

export default connect(mapStateToProps)(({themePrimaryColor, height}) => {
    const width = (893.61704 / 736.75) * height;


    return (<svg height={height} viewBox="0 0 893.61704 736.75" width={width} xmlns="http://www.w3.org/2000/svg">
        <path d="m321.94148 651.875s332.5-277.5 524 0" fill="#f2f2f2" transform="translate(-153.44148 -81.375)"/>
        <ellipse cx="714.11704" cy="712.75" fill="#f2f2f2" rx="154" ry="24"/>
        <ellipse cx="739.11704" cy="703.75" fill="none" rx="154" ry="24" stroke="#3f3d56"
                 strokeMiterlimit="10"
        />
        <g transform="translate(-153.44148 -81.375)">
            <path
                d="m958.04692 536.82505a15.5288 15.5288 0 1 0 2.068-29.78188l.38869 7.99268-3.78149-6.8498a15.47025 15.47025 0 0 0 -7.97138 8.74657 15.227 15.227 0 0 0 -.89321 4.2006 15.52584 15.52584 0 0 0 10.18939 15.69183z"
                fill="#57b894"/>
            <path
                d="m976.75782 629.52653c-1.33486-6.79742 4.44939-12.82355 10.16291-16.74051s12.38944-7.76554 14.3389-14.41283c2.80172-9.55332-5.54375-18.30237-12.03925-25.84718a93.48988 93.48988 0 0 1 -12.32871-18.32295 25.22231 25.22231 0 0 1 -2.94886-8.33146c-.51215-4.23462.8483-8.44847 2.21891-12.48774q6.8489-20.184 14.64532-40.0298"
                fill="none" stroke="#3f3d56" strokeMiterlimit="10"/>
            <path
                d="m958.68644 534.76727a15.5288 15.5288 0 1 0 2.068-29.78187l.38868 7.99267-3.78148-6.84979a15.4702 15.4702 0 0 0 -7.97138 8.74656 15.22718 15.22718 0 0 0 -.89321 4.2006 15.52583 15.52583 0 0 0 10.18939 15.69183z"
                fill="none" stroke="#3f3d56" strokeMiterlimit="10"/>
            <path
                d="m974.85982 490.59386a15.51068 15.51068 0 0 1 11.177-9.84713l1.27857 7.59841 2.37047-7.98034a15.52689 15.52689 0 1 1 -14.826 10.22906z"
                fill="#57b894"/>
            <path
                d="m975.71251 487.85016a15.5107 15.5107 0 0 1 11.177-9.84713l1.27857 7.59841 2.37047-7.98034a15.52689 15.52689 0 1 1 -14.826 10.22906z"
                fill="none" stroke="#3f3d56" strokeMiterlimit="10"/>
            <path
                d="m986.49448 572.68a15.52693 15.52693 0 0 0 13.743-27.62365l-1.82148 6.13265-1.29158-7.688a.2711.2711 0 0 0 -.03953-.015 15.52776 15.52776 0 1 0 -10.59039 29.194z"
                fill="#57b894"/>
            <path
                d="m987.23331 569.90092a15.52693 15.52693 0 0 0 13.743-27.62365l-1.82146 6.13262-1.29159-7.688a.27093.27093 0 0 0 -.03952-.015 15.52776 15.52776 0 1 0 -10.59039 29.194z"
                fill="none" stroke="#3f3d56" strokeMiterlimit="10"/>
            <path
                d="m970.32819 598.46815a15.52044 15.52044 0 1 0 2.99241-12.15992l6.91859 10.41337-9.4534-5.52709a15.38252 15.38252 0 0 0 -.4576 7.27364z"
                fill="#57b894"/>
            <path
                d="m970.96771 596.41038a15.52043 15.52043 0 1 0 2.9924-12.15993l6.9186 10.41337-9.4534-5.52708a15.38252 15.38252 0 0 0 -.4576 7.27364z"
                fill="none" stroke="#3f3d56" strokeMiterlimit="10"/>
            <path
                d="m955.19813 638.73381s13.703-5.37011 17.83272-8.31084 21.07864-6.45212 22.10312-1.73584 20.593 23.45665 5.12244 23.58144-35.94679 2.53829-40.06857.02754-4.98971-13.5623-4.98971-13.5623z"
                fill="#656380"/>
            <path
                d="m1000.5325 650.62681c-15.4706.12482-35.94678 2.53831-40.06857.02756-3.139-1.91207-4.38981-8.773-4.80833-11.9384-.28984.01246-.45746.01783-.45746.01783s.86792 11.05155 4.98971 13.5623 24.598.09727 40.06857-.02755c4.46575-.036 6.00829-1.62487 5.9236-3.97809-.6204 1.42176-2.3236 2.30954-5.64752 2.33635z"
                opacity=".2"/>
        </g>
        <rect fill={themePrimaryColor} height="158" rx="11.85052" width="60" x="159" y="413"/>
        <rect fill={themePrimaryColor} height="253" rx="11.85052" width="60" x="273" y="318"/>
        <rect fill={themePrimaryColor} height="319" rx="11.85052" width="60" x="387" y="252"/>
        <rect fill={themePrimaryColor} height="382" rx="11.85052" width="60" x="501" y="189"/>
        <rect fill={themePrimaryColor} height="492" rx="11.85052" width="60" x="615" y="79"/>
        <rect fill="none" height="158" rx="11.85052" stroke="#3f3d56" strokeMiterlimit="10" width="60"
              x="167" y="405"
        />
        <rect fill="none" height="253" rx="11.85052" stroke="#3f3d56" strokeMiterlimit="10" width="60"
              x="281" y="310"
        />
        <rect fill="none" height="319" rx="11.85052" stroke="#3f3d56" strokeMiterlimit="10" width="60"
              x="395" y="244"
        />
        <rect fill="none" height="382" rx="11.85052" stroke="#3f3d56" strokeMiterlimit="10" width="60"
              x="509" y="181"
        />
        <rect fill="none" height="492" rx="11.85052" stroke="#3f3d56" strokeMiterlimit="10" width="60"
              x="623" y="71"
        />
        <path d="m861 571.5h-861" fill="none" stroke="#3f3d56" strokeMiterlimit="10"/>
        <path d="m960.14462 556.951s9.41086 33.87907-2.50956 38.89819-8.15607-37.64341-8.15607-37.64341z" fill="#a0616a"
              transform="translate(-153.44148 -81.375)"/>
        <path
            d="m874.81957 774.028s-8.78346-.62739-11.92041 1.25478a7.57079 7.57079 0 0 1 -6.2739.62739s-13.80259-1.25478-8.78346 5.64651 10.0382 9.41083 10.0382 12.54778 20.70387 3.13695 21.33126 0 1.25478-19.44909 0-20.07648-4.39169.00002-4.39169.00002z"
            fill="#575a89" transform="translate(-153.44148 -81.375)"/>
        <path
            d="m898.033 774.028s8.78347-.62739 11.92042 1.25478a7.57079 7.57079 0 0 0 6.2739.62739s13.80258-1.25478 8.78346 5.64651-10.03824 9.41085-10.03824 12.5478-20.70388 3.13695-21.33127 0-1.25478-19.44909 0-20.07648 4.39173 0 4.39173 0z"
            fill="#575a89" transform="translate(-153.44148 -81.375)"/>
        <path
            d="m849.09658 550.04971s-4.39174 99.12764-1.88217 104.14676 11.293 89.71678 10.03824 94.73591-7.52868 25.723 0 28.85994 23.84082 0 23.84082 0 4.39173-25.0956 2.50956-38.2708-2.50956-68.38552-2.50956-68.38552l12.5478-57.71989 4.39173 54.58294s1.25478 74.65942-7.52868 89.71679 3.13695 21.33126 3.13695 21.33126 23.84083 3.13695 26.35039 2.50956-1.88217-25.0956 0-35.76124 6.2739-70.26769 10.03824-84.69766 25.0956-94.73591 9.41085-111.048-90.34417-.00005-90.34417-.00005z"
            fill="#3f3d56" transform="translate(-153.44148 -81.375)"/>
        <circle cx="741.45457" cy="246.57861" fill="#a0616a" r="21.95865"/>
        <path
            d="m878.58391 337.99185s3.76434 30.11473 1.88217 30.74212 33.25168 0 33.25168 0-6.90129-18.19431 0-32.62429z"
            fill="#a0616a" transform="translate(-153.44148 -81.375)"/>
        <path d="m878.58391 362.46007s15.29117-15.05736 33.36858 0l1.17041 4.48832-9.44338-.09659h-25.09561z"
              fill="#f2f2f2" transform="translate(-153.44148 -81.375)"/>
        <path
            d="m953.24333 555.06883c-1.4493.28858-5.00028.87832-9.6305 1.581-15.41486 2.32763-42.73145 5.85356-43.69766 3.43812-1.25478-3.13695-5.64651-14.43-8.78346-9.41085s-1.25478 9.41085-14.43 10.03824c-8.96543.42662-21.99633-.89092-29.21132-2.9613-3.38785-.97245-5.49594-2.108-5.29514-3.3126.62739-3.76434 7.52869-75.28681 7.52869-82.1881s2.50956-40.153 2.50956-40.153l-18.03119-35.4789-1.41791-2.7919s1.88217-6.90129 7.52868-8.15607 32.62432-16.93947 32.62432-16.93947 5.64651-8.78346 16.31214-8.15607 13.17519-3.76434 27.60517 8.15607c0 0 17.56692 7.52868 23.84082 11.293s13.17519 3.76434 13.80258 10.03824c.12545 1.26106-.55846 3.68906-1.7316 6.757-4.29753 11.24286-15.15769 31.13737-16.858 34.23668-.15057.26977-.23213.4141-.23213.4141s-.32625 11.28046.01884 24.487v.03136c.33882 12.667 1.29246 27.10326 3.7455 35.08368 5.01915 16.31212 20.07651 62.73894 13.80261 63.99377z"
            fill="#575a89" transform="translate(-153.44148 -81.375)"/>
        <path
            d="m923.40248 318.93963a14.95811 14.95811 0 0 0 -2.48168-5.54561 29.96345 29.96345 0 0 0 -4.47307-12.90477 13.53957 13.53957 0 0 0 -4.00742-4.62543 11.83 11.83 0 0 0 -6.30484-1.57192c-.40024-.00667-1.12038.11472-1.76852.14425-.41464-.1284-.83325-.243-1.25287-.35077-.245-.54483-.7304-2.13268-.86572-2.02515l-1.82956 1.45367c-.77351-.13437-1.55109-.23906-2.3315-.32208q-.4262-1.21571-.85247-2.43141a16.66052 16.66052 0 0 0 -1.20875 2.27177q-1.16521-.05711-2.332-.04l-1.555-1.49373a2.193 2.193 0 0 1 -1.71544 1.68029c-.71554.07341-1.42817.1693-2.13843.28356-.05782-.00038-.11648 0-.17316-.00142-3.82944-.0955-7.95-.2672-11.15226 1.83515a10.98929 10.98929 0 0 0 -3.55 4.02929c-.392.26648-.78539.531-1.16633.81353a12.74754 12.74754 0 0 0 -3.28468 3.25643 13.80875 13.80875 0 0 0 -1.45344 3.52187 33.76072 33.76072 0 0 0 -1.67317 11.5335 17.11735 17.11735 0 0 0 4.20783 10.68276c1.64238 1.76462 3.73018 3.06768 5.37478 4.83023 3.38632 3.62916 4.02106 8.69067 6.91745 12.53068 3.06351 4.06153 8.65488 6.73418 13.5654 7.50023a22.65988 22.65988 0 0 0 16.88041-4.21 10.44317 10.44317 0 0 0 2.87411-3.01334c1.70434-2.948.982-6.65327 1.61473-9.99915 1.18612-6.27148 7.17134-11.53441 6.1356-17.83243z"
            fill="#3f3d56" transform="translate(-153.44148 -81.375)"/>
        <path d="m839.68572 558.83317s9.41086 33.87907-2.50956 38.89819-8.15607-37.64341-8.15607-37.64341z"
              fill="#a0616a" transform="translate(-153.44148 -81.375)"/>
        <path
            d="m850.97247 481.95276s-4.11572 61.08273-3.48205 75.80128c-3.38785-.97245-5.49594-2.108-5.29514-3.3126.62739-3.76434 7.52869-75.28681 7.52869-82.1881s2.50956-40.153 2.50956-40.153l-18.03119-35.4789c.28858-1.75673.46426-2.7919.46426-2.7919l9.09716 19.1354 12.8615 3.45065z"
            opacity=".1" transform="translate(-153.44148 -81.375)"/>
        <path
            d="m837.17616 390.06523-4.39173 3.76434s-5.64651 33.25168-5.01912 41.40775-13.17519 131.75192-2.50956 131.75192 22.58605-1.88217 20.70388-6.2739 3.13328-78.76242 3.13328-78.76242l5.65018-65.5373z"
            fill="#575a89" transform="translate(-153.44148 -81.375)"/>
        <path
            d="m952.76651 396.8222c-4.29753 11.24286-15.15769 31.13737-16.858 34.23668.05017-8.0243.08157-13.70218.08157-13.70218l13.17519-26.97777 2.82325-2.19587s.30117 3.31888.77799 8.63914z"
            opacity=".1" transform="translate(-153.44148 -81.375)"/>
        <path
            d="m953.24333 555.06883c-1.4493.28858-5.00028.87832-9.6305 1.581-2.4028-17.76766-8.82726-68.86231-8.41952-73.67442.20081-2.37151.37021-14.09115.50194-26.98405.33882 12.667 1.29246 27.10326 3.7455 35.08368 5.01912 16.31214 20.07648 62.73896 13.80258 63.99379z"
            opacity=".1" transform="translate(-153.44148 -81.375)"/>
        <path
            d="m945.08726 385.6735 9.41085 2.50956s6.27391 69.6403 6.9013 81.56072 8.15607 85.32505 6.90129 88.462-20.70388 7.52868-21.33127 4.39173-9.74794-73.92136-9.2657-79.6232-2.65471-64.67652-2.65471-64.67652z"
            fill="#575a89" transform="translate(-153.44148 -81.375)"/>
        <path d="m196.5 344.5 114-95 115-65 114-63 114-110" fill="none" stroke="#3f3d56" strokeMiterlimit="10"/>
        <circle cx="193.5" cy="349.5" fill="#f2f2f2" r="11"/>
        <circle cx="307.5" cy="254.5" fill="#f2f2f2" r="11"/>
        <circle cx="422.5" cy="189.5" fill="#f2f2f2" r="11"/>
        <circle cx="536.5" cy="126.5" fill="#f2f2f2" r="11"/>
        <circle cx="650.5" cy="16.5" fill="#f2f2f2" r="11"/>
        <circle cx="196.5" cy="344.5" fill="none" r="11" stroke="#3f3d56" strokeMiterlimit="10"/>
        <circle cx="310.5" cy="249.5" fill="none" r="11" stroke="#3f3d56" strokeMiterlimit="10"/>
        <circle cx="425.5" cy="184.5" fill="none" r="11" stroke="#3f3d56" strokeMiterlimit="10"/>
        <circle cx="539.5" cy="121.5" fill="none" r="11" stroke="#3f3d56" strokeMiterlimit="10"/>
        <circle cx="653.5" cy="11.5" fill="none" r="11" stroke="#3f3d56" strokeMiterlimit="10"/>
    </svg>);
});
