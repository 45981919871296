import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import LogoRender from '../../cmp/LogoRender';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import FormattedHelmet from '../../cmp/intl/FormattedHelmet';
import RenderLegalDocLink from '../../cmp/RenderLegalDocLink';
import AccountCreationForm from './AccountCreationForm';
import {Sticky, StickyContainer} from 'react-sticky';

function CreateAccount({signupPageTitle, page_data, page_handle, signupData}){
    const [width, setWidth] = useState(window.innerWidth)

    function handleWindowSizeChange(){
        setWidth(window.innerWidth)
    }

    useEffect(()=>{
        window.addEventListener('resize', handleWindowSizeChange);
        return ()=>{
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])
        const isMobile = width <= 768;
        return (
            <div className={'container mx-auto'} style={{marginTop: 64}}>
                <FormattedHelmet id={signupPageTitle}/>
                <div className="row align-items-end">
                    <div className="col-md-8">
                        <LogoRender/>
                    </div>
                    <div className="col-md-4 text-right">
                        <Link to={'/login'}>
                            <FormattedMessage id={'Login'}/>
                        </Link>
                    </div>
                </div>
                <StickyContainer>
                    <div className={'card shadow mt-2'}>
                        <div className={'row no-gutters'}>
                            <div className="col-md-6">
                                {
                                    isMobile ? <div className="card-body">
                                            <h1><FormattedMessage id={signupPageTitle}/></h1>
                                            <AccountCreationForm page_data={page_data}
                                                                 page_handle={page_handle}/>
                                        </div> :
                                        <Sticky>{({style}) => (<div style={style}>
                                            <div className="card-body">
                                                <h3><FormattedMessage id={signupPageTitle}/></h3>
                                                <AccountCreationForm page_data={page_data}
                                                                     page_handle={page_handle}/>
                                            </div>
                                        </div>)}</Sticky>
                                }
                            </div>
                            <div className="col-md-6 bg-light">
                                <div className="card-body">
                                    <div dangerouslySetInnerHTML={{__html: signupData.html}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </StickyContainer>
                <small className={'d-block text-muted mt-4 px-4 text-center'}>
                    <FormattedMessage id={'tosAgree'}/>
                </small>
                <CreateAcctFooter isEmbedded={false}/>
            </div>
        );
}

function CreateAcctFooter({isEmbedded}) {

    return (<div>
        {
            !isEmbedded && <hr/>
        }
        {
            !isEmbedded &&
            <div className={'mt-4 mb-4 d-flex justify-content-between'}>
                <RenderFooterUrl type={'terms_url'}/>
                <RenderFooterUrl type={'privacy_url'}/>
            </div>
        }
    </div>);
}


function RenderFooterUrl({type}) {
    return (<small><RenderLegalDocLink type={type}/></small>);
}


function mapStateToProps(state, props) {
    const {isEmbedded, signupData, signupPageTitle} = props.page_data ? props.page_data : state.settings;
    return {
        signupData: signupData ? JSON.parse(signupData) : {},
        signupPageTitle: signupPageTitle || 'Create Account',
        isEmbedded
    };
}

export default connect(mapStateToProps)(CreateAccount);
