import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {post} from '../../api';
import AuthContainer from './components/AuthContainer';
import LogoRender from '../cmp/LogoRender';
import Button from '../cmp/themed/StyledButton';
import {FormattedMessage} from 'react-intl';
import FormInput from './components/FormInput';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import RenderError from './components/RenderError';
import {connect} from 'react-redux';
import useInputState from '../cmp/hooks/useInputState';

export const emailValidator = new RegExp(/\S+@\S+\.\S+/);

function ForgotPassword({allow_partner_registrations}) {
    const [email, setEmail] = useInputState('')
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null)
    const [errorCode, setError] = useState(null)
    const handleFormSubmit = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setLoading(true)
        setSuccess(false)
        post('/forgotpassword', {
            email
        }).then((data) => {
            if (data.success) {
                setSuccess(<p className={'alert alert-success mb-0'}>
                        <FormattedMessage id={'recoverLinkSent'} values={{email}}/>
                    </p>
                );
            } else {
                setError(data.code)
            }
            setLoading(false)
        }).catch((e) => {
            setLoading(false)
        });

    }

    return (
        <AuthContainer>
            <FormattedHelmet id={  document.location.href.includes('?change=true') ? 'Change Password' : 'recoverPassword'}/>
            <div className={'text-center mb-4'}>
                <LogoRender/>
            </div>
            <div className="text-center">
                {
                    document.location.href.includes('?change=true') ?
                        <FormattedMessage tagName={"h1"} id={'Change Password'}
                                          defaultMessage={"Change Password"}/> :
                        <FormattedMessage tagName={'h1'} id={'recoverPassword'}/>
                }
            </div>
            <div className={'card shadow-sm'}>
                <div className={'card-body'}>
                    {
                        success ? success : null
                    }
                    {
                        errorCode && <RenderError code={errorCode}/>
                    }
                    {
                        !success ? (
                            <form onSubmit={handleFormSubmit}>
                                <p className={'text-muted'}>
                                    <FormattedMessage id={'recoverPasswordFormDesc'}/>
                                </p>
                                <FormInput id={'emailAddress'}>
                                    <input type={'email'}
                                           name={'email'}
                                           autoComplete={"email"}
                                           onChange={setEmail}
                                           value={email}
                                           className={'form-control'}
                                    />
                                </FormInput>
                                <div className={'row justify-content-between align-items-center'}>
                                    <div className={'col'}>
                                            <Button
                                                disabled={!email || !emailValidator.test(email)}
                                                loading={loading}
                                                className={'btn btn-primary'}
                                                onClick={handleFormSubmit}>
                                                {
                                                    document.location.href.includes('?change=true') ?
                                                        <FormattedMessage id={'Change Password'}
                                                                          defaultMessage={"Change Password"}/> :
                                                        <FormattedMessage  id={'recoverPassword'}/>
                                                }
                                            </Button>
                                    </div>
                                </div>
                            </form>
                        ) : null
                    }
                </div>
            </div>
            <div className={'mt-4'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-auto'}>
                        <Link to={'/login'}>
                            <FormattedMessage id={'Login'}/>
                        </Link>
                    </div>
                    {
                        allow_partner_registrations ?
                        <div className={'col-auto create-account'}>
                            <Link to={'/create-account'}>
                                <FormattedMessage id={'createAccount'}/></Link>
                        </div> : null
                    }
                </div>
            </div>
        </AuthContainer>
    );
}

function mapStateToProps(state) {
    return {
        allow_partner_registrations: state.settings.allow_partner_registrations
    }
}

export default connect(mapStateToProps)(ForgotPassword);
