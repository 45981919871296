import ReactGA from 'react-ga';

import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

function ReactGaIntegration({google_analytics}){
    const location = useLocation()
    useEffect(()=>{
        ReactGA.initialize(google_analytics);
    },[google_analytics])
    useEffect(()=>{
        ReactGA.pageview(location.pathname + location.search);
    },[location])
    return null;
}

export default ReactGaIntegration;

