import {combineReducers} from 'redux';
import settingsReducer from './settings/settingsReducer';
import cart_reducer from "../components/pages/inportalstore/redux/cart_reducer";
import commissionsReducer from "./commissions/commissionsReducer";
import sitesReducer from "./sites/sitesReducer";
import projectionReducer from "../components/pages/projection-templates/redux/projectionReducer";
import linksReducer from "./linkbox/linksReducer";

export default combineReducers({
	settings: settingsReducer,
	cartItems: cart_reducer,
	commissions: commissionsReducer,
	sites: sitesReducer,
	projections: projectionReducer,
	links: linksReducer,
});
