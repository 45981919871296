import React from 'react';

import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import BootstrapModal from 'react-bootstrap/Modal';

import StyledButton from "./themed/StyledButton";

export default function Modal ({confirmLoading, onOk, onCancel, onClose, okText, cancelText, title, visible, onSubmit, children, size, disabled}:{
    confirmLoading?: boolean;
    onOk?: any;
    onCancel?: any;
    onClose?: any;
    okText?: string | React.ReactNode;
    cancelText?: string | React.ReactNode;
    title?: string | React.ReactNode;
    visible?: boolean;
    onSubmit?: any;
    children?: React.ReactNode;
    size?: 'lg' | 'sm';
    disabled?: boolean;

}) {
    const OkButton = okText ? typeof okText === "string" ? <FormattedMessage id={okText} /> : okText : <FormattedMessage id={"OK"} />;
    const CancelButton = cancelText ? typeof  cancelText === "string" ? <FormattedMessage id={cancelText} /> : cancelText : <FormattedMessage id={"Cancel"} />

    return (
        <BootstrapModal show={visible} onHide={onCancel || onClose} size={size}>
            {
                (title || onClose) &&
                <BootstrapModal.Header closeButton={onCancel || onClose}> <BootstrapModal.Title>
                    {
                        typeof title === "string" ?
                            <FormattedMessage id={title} defaultMessage={title}/>
                            : title
                    }</BootstrapModal.Title>
                </BootstrapModal.Header>
            }
            <Form onSubmit={onSubmit}>
                <BootstrapModal.Body>
                    {children}
                </BootstrapModal.Body>
                {
                    (onCancel || onSubmit || onOk || onCancel) &&
                    <BootstrapModal.Footer>
                        {
                            onCancel ?
                                <button type={"button"} className={"btn btn-outline mr-1"}
                                        onClick={onCancel}>{CancelButton}</button> :
                                null
                        }
                        {
                            (onSubmit || onOk || onCancel) ?
                            <StyledButton className={"btn"}
                                          loading={confirmLoading}
                                          type={"submit"} disabled={disabled}
                                          onClick={onSubmit || onOk || onCancel}>{OkButton}</StyledButton> : null
                        }
                    </BootstrapModal.Footer>
                }
            </Form>
        </BootstrapModal>
    );
}


function Form({onSubmit, children}) {
    return onSubmit ? <form onSubmit={onSubmit}>{children}</form> : children
}


Modal.propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    confirmLoading: PropTypes.bool,
    onOk: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'sm'])
}
