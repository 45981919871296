import React from 'react';
import BootstrapDropdown from 'react-bootstrap/Dropdown';
import PropTypes from 'prop-types';
import {ThreeDotsHorizontalIcon} from '../../icons/Icons';

function Dropdown({onSelected, opts, selected, direction}) {
        const label = opts.find((item)=>item.value == selected)?.label;
        return (
            <BootstrapDropdown drop={direction}>
                <BootstrapDropdown.Toggle as={"button"} className={'btn btn-link btn-sm px-0'}>
                    <span>{label}</span>
                </BootstrapDropdown.Toggle>
                <BootstrapDropdown.Menu>
                    {
                        opts.map((item) => <BootstrapDropdown.Item key={String(item.value)}
                                                         onClick={()=>onSelected(item.value)}>{item.label}</BootstrapDropdown.Item>)
                    }
                </BootstrapDropdown.Menu>
            </BootstrapDropdown>
        )
}

export const ThreeDotsButton = React.forwardRef(({ children, onClick }, ref)=>(
    <button ref={ref} onClick={(e)=>{
        e.preventDefault();
        onClick(e)
  }} className={"btn btn-link p-0"}><ThreeDotsHorizontalIcon /></button>
));

Dropdown.propTypes = {
    opts: PropTypes.array.isRequired,
    selected: PropTypes.any.isRequired,
    onSelected: PropTypes.func.isRequired,
}

export default Dropdown;
