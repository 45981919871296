import * as localStorage from './tools/storage';
import axios from 'axios';
export const isDev = process.env.NODE_ENV === 'development' || localStorage.getItem('isdev');
export function getHttpClient(website_match){
	return axios.create({
		baseURL:getServer(),
		headers:buildHeaders(website_match)
	})
}

var access_token = null
export function getAccessToken(){
	try {
		const token =  access_token ||
				sessionStorage.getItem('access_token') ||
				localStorage.getItem('access_token')
		access_token = token;
		return token
	}catch(e){
		return access_token
	}
}
export function setAccessToken(token, inSessionOnly = false){
	access_token = token;
	try {
		if (inSessionOnly) {
			sessionStorage.setItem('access_token', token)
		} else {
			localStorage.setItem('access_token', token)
		}
	}catch(e){

	}
}

export function removeAccessToken(){
	access_token = null;
	try {
		sessionStorage.removeItem('access_token')
		localStorage.removeItem('access_token')
	}catch(e){

	}
}
function getServer(){
	if(isDev){
		return 'https://api-server-3.goaffpro.com'
	}else{
	   return 'https://api.goaffpro.com'
	}
}

export const apiServer = getServer();
export const apiUrl = getServer()+'/partner'
export function buildUri(endpoint, query){
	const baseUri = endpoint && endpoint.substr(0,8) === "https://" ? endpoint : `${apiUrl}${endpoint}`;
	if (query) {
		const u = new URL(baseUri);
		Object.keys(query).forEach((item) => {
			if (query[item] !== undefined) {
				u.searchParams.append(item, query[item]);
			}
		});
		return u.href;
	}
	return baseUri;

}

export function post(endpoint, data){
	const headers = buildHeaders();
	headers['content-type'] = 'application/json';
	return fetch(buildUri(endpoint), {
		method: 'POST',
		credentials: 'include',
		headers,
		body: JSON.stringify(data)
	}).then((data) =>{
		console.log(data.status);
		return data.json();
	})
}

export function patch(endpoint, data){
	const headers = buildHeaders();
	headers['content-type'] = 'application/json';
	return fetch(buildUri(endpoint), {
		method: 'PATCH',
		credentials: 'include',
		headers,
		body: JSON.stringify(data)
	}).then((data) =>{
		console.log(data.status);
		return data.json();
	})
}

export function upload(endpoint, body, onUploadProgress){
	const headers = buildHeaders();
	return axios.post('https://api2.goaffpro.com/partner'+endpoint, body, {
		headers,
		onUploadProgress: (progressEvent) => {
			// axios progress event get upload percentage
			let percentage = Math.floor(
				(progressEvent.loaded * 100) / progressEvent.total
			);
			onUploadProgress({
				percentage,
				size: progressEvent.total,
				uploaded: progressEvent.loaded
			});
		}
	}).then(({data})=>data)
	// return fetch(buildUri(endpoint), {
	// 	method: 'POST',
	// 	credentials: 'include',
	// 	headers,
	// 	body
	// }).then((data) => data.json());
}

export function buildHeaders(xShop){
	const headers =  {
	};
	if(getAccessToken()) {
		headers.Authorization = `Bearer ${getAccessToken()}`
	}

	if (isDev){
		headers['x-shop'] = 'helloworld.goaffpro.com';
	}
	if(localStorage.getItem('x-shop')){
		headers['x-shop'] = localStorage.getItem('x-shop');
	}
	if(xShop){
		headers['x-shop'] = xShop
	}
	return headers;
}



export function get(endpoint, query, abortController){
	const headers = buildHeaders();
	headers['content-type'] = 'application/json';
	return fetch(buildUri(endpoint, query),{
		method: 'GET',
		signal: abortController ? abortController.signal : undefined,
		credentials: 'include',
		headers,
	}).then((response) => {
		if (response.ok){
			return response;
		}else if(response.status === 403) {
			removeAccessToken()

		}else {
			throw response
		}
	}).then(async (data) => {
		const totp_required = data.headers.get('x-totp-required');
		const result = data ?  await data.json() : {}
		return totp_required ? {...result, totp_required} : result;
	});
}

export function del(endpoint){
	return fetch(buildUri(endpoint),{
		method: 'DELETE',
		credentials: 'include',
		headers: buildHeaders()
	}).then((response) => {
		if (response.ok){
			return response;
		}
		throw new Error(response.status);

	}).then((data) => data.json());
}
