import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import Modal from '../../../cmp/Modal';
import CurrencyNumber from '../../../cmp/CurrencyNumber';
import Big from 'big.js';
import Button from '../../../cmp/form/Button'
import {FiCheck} from 'react-icons/fi';
import {FormattedMessage} from 'react-intl';


function buildUrl(website_match, variant_id, affiliate_id, plan_id, selling_plan_id, selectedUpsells, profile = null){
  const searchParams = new URLSearchParams();
  searchParams.append('note', 'Goaffpro memberships');
  searchParams.append('attributes[Membership Affiliate ID]', affiliate_id);
  searchParams.append('attributes[Membership Plan ID]', plan_id);

  const items = [
    { variantId: variant_id, quantity:1, selling_plan_id},
    ...(selectedUpsells||[]).map((i)=>({variantId: i, quantity:1}))
  ]
  for(let x =0; x < items.length; x++){
    const {variantId, quantity, selling_plan_id} = items[x]
    searchParams.append(`items[${x}][id]`, variantId)
    if(selling_plan_id) {
      searchParams.append(`items[${x}][selling_plan]`, selling_plan_id)
    }
    searchParams.append(`items[${x}][quantity]`, String(quantity));
  }
  // searchParams.append('items[0][id]', variant_id)
  // if(selling_plan_id) {
  //   searchParams.append('items[0][selling_plan]', selling_plan_id)
  // }
  // searchParams.append('items[0][quantity]', "1");
  //
  // if(selectedUpsells && selectedUpsells.length > 0){
  //   selectedUpsells.forEach((i, index)=> {
  //     searchParams.append(`items[${index + 1}][id]`, i)
  //     searchParams.append(`items[${index+1}][quantity]`, "1");
  //   })
  // }
  if(profile){
    // if(profile.email) {
    //   searchParams.append('checkout[email]', profile.email)
    // }
    // searchParams.append('shippingAddress.address1',profile.address_1)
    // if(profile.address_1){
    //   searchParams.append('checkout[shipping_address][address1]', profile.address_1)
    // }
    // if(profile.address_2){
    //   searchParams.append('checkout[shipping_address][address2]', profile.address_2)
    // }
    // if(profile.city){
    //   searchParams.append('checkout[shipping_address][city]', profile.city)
    // }
    // if(profile.country){
    //   searchParams.append('checkout[shipping_address][country]', profile.country)
    // }
    // if(profile.state){
    //   searchParams.append('checkout[shipping_address][province]', profile.state)
    // }
    // if(profile.zip){
    //   searchParams.append('checkout[shipping_address][zip]', profile.zip)
    // }
    // if(profile.fname){
    //   searchParams.append('checkout[shipping_address][first_name]', profile.fname)
    // }
    // if(profile.lname){
    //   searchParams.append('checkout[shipping_address][last_name]', profile.lname)
    // }

  }
  searchParams.append('return_to', `/checkout`);
//     return `https://${website_match}/cart/add?${searchParams.toString()}`
  return `https://${website_match}/cart/clear?return_to=${encodeURIComponent(`/cart/add?${searchParams.toString()}`)}`
}

function buildCheckoutLink({product, selectedUpsells, website_match, selling_plan_id, affiliate_id, plan_id, storefront_token}){
  const items = [
    { variantId: `gid://shopify/ProductVariant/${product.value}`, quantity:1}
  ]
  selectedUpsells.forEach(({value})=>{
    items.push({ variantId: `gid://shopify/ProductVariant/${value}`, quantity:1})
  })
  return axios.post(`https://${website_match}/api/2023-07/graphql.json`,{
    query:`mutation checkoutCreate($input: CheckoutCreateInput!) {
              checkoutCreate(input: $input) {
                checkout {
                  id
                  webUrl
                  note
                }
                checkoutUserErrors {
                  code
                  field
                  message
                }
              }
        }`,
    variables: { input: { note:'Goaffpro memberships', lineItems: items, customAttributes: [
          {
            key:'Membership Affiliate ID',
            value:String(affiliate_id)
          },
          {
            key:'Membership Plan ID',
            value:String(plan_id),
          }
        ]}
    }
  }, {
    headers:{
      'X-Shopify-Storefront-Access-Token': storefront_token
    }
  }).then(({data})=>{
    return data.data.checkoutCreate.checkout.webUrl
  }).catch((e)=>{
    return  buildUrl(website_match, product.variation_id, affiliate_id, plan_id, selling_plan_id, selectedUpsells)
  })
}
const reducer = (state)=>
    [state.settings.website_match, state.settings.affiliate_id,{
      fname:state.settings.fname,
      lname:state.settings.lname,
      email:state.settings.email,
      address_1:state.settings.address_1,
      address_2:state.settings.address_2,
      city:state.settings.city,
      state:state.settings.state,
      country:state.settings.country,
      zip:state.settings.zip,
    }]
function ShopifyBuyButton({product, upsells: originalUpsells, storefront_token,selling_plan_id, plan_id}) {
  const [website_match, affiliate_id, profile] = useSelector(reducer)
  const [url, setURL] = useState(null)
  const upsells = originalUpsells.filter((i)=>!i.required)
  const [loading, setLoading] = useState(false)
  const [upsellModal, showUpsellModal] = useState(false)
  const [selectedUpsells, setSelectedUpsells] = useState(originalUpsells.filter((i)=>i.required).map((i)=>i.value))
  useEffect(()=>{
    if(affiliate_id && plan_id && product && website_match){
      if(storefront_token){
        setLoading(true)
        buildCheckoutLink({
          product,
          selectedUpsells,
          website_match,
          affiliate_id,
          plan_id,
          storefront_token
        }).then((url)=>{
          setLoading(false)
          setURL(url)
        })
      }else{
        setURL(buildUrl(website_match, product.variation_id, affiliate_id, plan_id, selling_plan_id, selectedUpsells, profile))
      }
    }
  },[product, selectedUpsells, selling_plan_id, affiliate_id, plan_id, storefront_token, website_match, profile])

  const checkout = ()=>{
    if(storefront_token){
      buildCheckoutLink({
        product,
        selectedUpsells,
        selling_plan_id,
        website_match,
        affiliate_id,
        plan_id,
        storefront_token,
        profile
      }).then((url)=>{
        showUpsellModal(false)
        window.open(url)
      })
    }else {
      showUpsellModal(false)
      window.open(
          buildUrl(website_match, product.variation_id, affiliate_id, plan_id,
              selling_plan_id, selectedUpsells, profile))
    }
  }
    if(product && website_match) {
      if (loading) {
        return <div className={"py-2"}><Skeleton width={90} height={36.5}/>
        </div>
      }
      return (
          <>
            <Modal title={"membership_special_offer_title"} visible={upsellModal}
                   onOk={checkout}
                   okText={"Checkout"}
                   size={"lg"}

                   onClose={()=>showUpsellModal(false)}>

                <p><FormattedMessage id={"membership_special_offer_subtitle"}/></p>
                <div className="row row-cols-1 g-4">
              {
                upsells.map(({product, value})=>{
                  // get variation
                  const i = product?.variants?.find(({id})=>id == value)
                  const {title, images} = product;
                  const variation_title = i.title;
                  const {price, compare_at_price} = i;
                  const variant_images = []
                  images.forEach(({variant_ids, src, height, width})=>{
                    if(variant_ids.indexOf(value) > -1) {
                      variant_images.push({src, height, width})
                    }
                  })
                  return <div className={"col"}>
                    <div className="row">
                      <div className="col-4">
                        <img alt={title} src={images[0].src}
                             style={{width:"100%", aspectRatio:'1/1', objectFit:'contain'}} />
                      </div>
                      <div className="col-8">
                        <h5>{title}</h5>
                        {product?.variants.length > 1 ? <small>{variation_title}</small> : null}
                        <div>
                          <div dangerouslySetInnerHTML={{__html: product.body_html}}></div>
                        </div>
                        <p className={"fs-5 mt-2"}><span className={"mr-2"}><CurrencyNumber value={price} /></span>
                          {
                            compare_at_price && compare_at_price > price ?
                                <>
                                  <span className={"ml-2"} style={{textDecoration:'line-through'}}><CurrencyNumber value={compare_at_price} /></span>
                                  <span
                                      className={"ml-2 rounded badge badge-danger"}>{Big(compare_at_price).
                                      minus(price).
                                      times(100).
                                      div(Number(compare_at_price)).
                                      toFixed(0)}% off</span>
                                </> : null
                          }</p>
                        <Button onClick={()=>setSelectedUpsells((s)=>s.indexOf(value) > -1 ? s.filter((x)=>x!== value) : [...s, value])}
                                className={`btn btn-outline-secondary`}>{selectedUpsells.indexOf(value) > -1 ? <FiCheck className={"mr-2"} /> : null  }
                          <FormattedMessage id="Add to cart"></FormattedMessage>
                        </Button>
                        </div>
                      </div>
                     </div>
                })
              }</div>
            </Modal>
          <div className={"py-2"}>
            <a href={upsells?.length > 1 ? null  : url} onClick={upsells.length > 1 ? ()=>showUpsellModal(true) : null} rel={"opener"} className={"btn btn-primary"} title={product.label}>Buy Now</a>
          </div></>
      );
    }
//  }
 return null;
}

function RenderProductItem(){

}

export default ShopifyBuyButton;
