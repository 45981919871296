import React, {useCallback, useEffect, useState} from 'react';
import useToggle from '../../../cmp/hooks/useToggle';
import FormGroup from '../../../cmp/form/FormGroup';
import {post} from '../../../../api';
import Input from '../../../cmp/form/Input';
import Modal from '../../../cmp/Modal';
import Spinner from '../../../cmp/Spinner';

export function ShopifyOrderListener(){
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderNumber = urlParams.get('number');
    const orderId = urlParams.get('id');

    if(orderNumber || orderId) {
      setLoading(true)
      post('/paymentplans/activate/store_purchase',{
        data:{
          order_number: orderNumber,
          order_id: orderId,
        }
      }).then((data)=>{
        if(data.success){
          window.location = "/"
        }else{
          setLoading(false)
          setError(data.error)
        }
      }).catch((e)=>{
        setLoading(false)
      })
    }
  }, []);
  useEffect(()=>{
    // from url query parameter (if the user is redirected to https://sample.goaffpro.com/?number=order-number-here
    const listener = (e)=>{
      try{
        const {type, id} = JSON.parse(e.data);
        if(type==="goaffpro" && id){
          setLoading(true)
          post('/paymentplans/activate/store_purchase',{
            data:{
              order_id: id,
            }
          }).then((data)=>{
            if(data.success){
              window.location = "/"
            }else{
              setLoading(false)
              setError(data.error)
            }
          }).catch((e)=>{
            setLoading(false)
          })
          //setLo
        }
      }catch(e){
//        console.log(e)
      }
    }
    window.addEventListener("message", listener)
    return ()=>{
        window.removeEventListener('message', listener)
    }
  },[])
  if(loading){
    return <div style={{position:'fixed', inset:0, background:'rgba(255,255,255,0.8)', zIndex:1}}>
        <div className="d-flex align-items-center justify-content-center min-vh-100">
          <Spinner size={32}  />
        </div>
    </div>
  }else{
    return null;
  }
}

function ShopifyTestForPurchase({plan_id, plan_title}) {
  const [visible, toggle] = useToggle()
  const [orderNumber, setOrderNumber] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const onSubmit = useCallback((e)=>{
    if(e){
      e.preventDefault();
      e.stopPropagation();
    }
    setLoading(true)
    setError(null)
    // activate for plan
    post('/paymentplans/activate/store_purchase',{
      data:{
        order_number: orderNumber,
      },
      plan_id,
      payment_gateway:'store_purchase',
    }).then((data)=>{
      setLoading(false)
      if(data.success){
        window.location = "/"
      }else{
        setError(data.error)
      }
    }).catch((e)=>{
      console.log(e)
      setError("Network error");
      setLoading(false)
    })
  },[orderNumber])
  return (
      <>
        <Modal onSubmit={onSubmit}
               title={plan_title}
               okText={"Submit"}
               confirmLoading={loading}
               visible={visible} onClose={toggle}>
            <FormGroup label={"Order Number"} info={"Enter your purchased order number to verify"}>
              <Input value={orderNumber} onChange={setOrderNumber} />
            </FormGroup>
          {
            error ? <p className={"text-danger"}>{error}</p> : null
          }
        </Modal>
        <div className="text-center">
          <button className="btn btn-sm btn-link already_purchased_link" onClick={toggle}>Already purchased ? Click here</button>
        </div>
      </>
  );
}

export default ShopifyTestForPurchase;
