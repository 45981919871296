import React, {useState} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

function CopyButton({text, title, style, className}) {
    const [copied, setCopied] = useState(false)
    function onCopy(){
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1000);
    }
    return (
        <CopyToClipboard text={text} onCopy={onCopy}>
            <button type={"button"} className={`copy-to-clipboard word-break btn ${className || 'p-0 btn-link'}`} style={style}
                    disabled={copied}>{!copied ? <FormattedMessage id={title  || 'Copy'}/> : <FormattedMessage id={'Copied'}/>}</button>
        </CopyToClipboard>
    );
}

CopyButton.propTypes = {
    text: PropTypes.string.isRequired,
    title: PropTypes.string
};

export default CopyButton;
