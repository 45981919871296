import React, {useCallback, useEffect, useState} from 'react';
import {post, setAccessToken} from '../../../api';
import Spinner from '../../cmp/Spinner';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {getCurrentQueryObject} from '../../../tools/func';

function ShopifyLoginIndex({sso_no_account_page, integration}) {
    const params = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] =useState(false);
    const [error, setError] = useState(null)

    const doLogin = useCallback(() => {
        setLoading(true)
        setError(null)
        post('/auth/shopify-login', {
            customer_id: params.customer_id,
            integration,
            force: !sso_no_account_page,
            metadata: getCurrentQueryObject(),
        }).then((data) => {
            setLoading(false)
            if (data.access_token) {
                setAccessToken(data.access_token)
                window.location = "/?embedded=1&is_shopify_login=1";
            } else if (sso_no_account_page) {
                navigate(sso_no_account_page,{replace:true})
            } else {
                const {code} = data;
                setError(code)
            }
        }).catch((e) => {
            setError(1)
            setLoading(false)
        });
    },[params.customer_id, sso_no_account_page])
    useEffect(()=>{
        doLogin()
    },[doLogin])
    if (loading) {
        return <div className="container">
            <div className="text-center" style={{paddingTop:100, paddingBottom:100}}><Spinner size={"lg"}/></div>
        </div>
    } else if (error) {
        return <div className="container">
            <p className={"text-danger"}>{error || "Error logging in"} </p>
        </div>
    }
    return null;
}

function mapStateToProps(state) {
    return {
        sso_no_account_page: state.settings.sso_no_account_page,
        subscription: state.settings.subscription,
    }
}

export default connect(mapStateToProps)(ShopifyLoginIndex);
