import React, {useEffect, useState} from 'react';
import StyledButton from '../cmp/themed/StyledButton';
import Input from '../cmp/form/Input';
import FormGroup from '../cmp/form/FormGroup';
import {get, post} from '../../api';
import LoadingSkeleton from '../cmp/LoadingSkeleton';
import {useSelector} from 'react-redux';
import CopyButton from '../cmp/CopyButton';
import {getItem, setItem} from '../../tools/storage';

window.addEventListener('message', function(e){
  try {
    const refer_a_friend_config =   window.store?.getState()?.settings?.refer_a_friend_config
    const d = e.data ? JSON.parse(e.data) : null;
    if (d && d.change) {
      window.store.dispatch({
        type: 'merge-settings',
        settings: {
          refer_a_friend_config: {
            ...(refer_a_friend_config || {}),
            widget: {
              ...(refer_a_friend_config.widget || {}),
              ...d.change,
            }
          }
        }
      })
    }
  }catch(e){}
})

const selector = (state)=>{
  const {
    hero_image, super_text, title, subtitle,
    custom_css,
    enter_friend_email_label,
    tab_refer_a_friend,
    tab_your_referrals,
    thank_you_subtitle,
    thank_you_title,
    message_no_referrals,
    placeholder_your_name,
      placeholder_your_email,
    friend_email_input_placeholder,
    friend_email_input_info,
    btn_title_next,
    btn_title_submit,
  } = state?.settings?.refer_a_friend_config?.widget || {}
  return {
    hero_image: hero_image ||  '/images/undraw_Referral_re_0aji.png',
    super_text:super_text || 'Refer a friend',
    title:title || 'GIVE $X, GET $Y',
    placeholder_your_name: placeholder_your_name ?? 'Your name',
    placeholder_your_email: placeholder_your_email ?? 'Your email address',
    enter_friend_email_label:enter_friend_email_label ?? 'Enter your friend\'s email address',
    thank_you_title: thank_you_title ?? 'THANKS!',
    tab_refer_a_friend: tab_refer_a_friend ?? 'Refer a friend',
    tab_your_referrals: tab_your_referrals ?? 'Your referrals',
    thank_you_subtitle: thank_you_subtitle ?? 'Remind your friends to check their emails',
    message_no_referrals: message_no_referrals ?? 'No referrals',
    friend_email_input_placeholder: friend_email_input_placeholder ?? 'Your friend\'s email address',
    friend_email_input_info:friend_email_input_info ?? 'eg. email1@example.com, email2@example.com',
    subtitle: subtitle || 'Give your friends $X off their order and get a $Y coupon code on each successful referral\n\nEnter your email address to start',
    btn_title_next:btn_title_next || 'Next',
    btn_title_submit:btn_title_submit || 'Submit',
  }
}


function ReferAFriend(props) {
  const [step, setStep] = useState(!!getItem('gfp_refer_a_friend_affiliate_id') ? 1: 0);
  const widget = useSelector(selector)
  const heroImage = widget?.hero_image || '/images/undraw_Referral_re_0aji.png'
  useEffect(()=>{
    if(widget?.custom_css){

    }
  },[widget?.custom_css])
  return (
      <>
        <div className="container-fluid">
          <div className="row row-cols-md-2 row-cols-1">
            <div className="col">
              <img src={heroImage} style={{width:'100%'}} alt=""/>
            </div>
            <div className="col flex-fill d-flex">
              {
                step === 0 ? <Enroll onEnrolled={()=>setStep(1)} /> : null
              }

              {
                step === 1 ? <MainApp /> : null
              }
            </div>
          </div>
        </div>
      </>
  );
}

function ThanksMessage(){
  const {thank_you_title, thank_you_subtitle} = useSelector(selector)
  return <div className={"text-center"}>
      <h2>{thank_you_title}</h2>
      <p>{thank_you_subtitle}</p>
  </div>
}

function MainApp(){
  const [activeTab, setActiveTab] = useState(0)
  const [affiliate, setAffiliate] = useState({})
  useEffect(()=>{
    get('/refer-a-friend/affiliate?affiliate_id='+getItem('gfp_refer_a_friend_affiliate_id'))
    .then((data)=>{
      setAffiliate(data.affiliate)
    })
  },[])
  return <div className={"flex-fill"}>
    <div className="px-4 py-2">
      <RenderTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      {
        activeTab === 1 ? <RenderYourReferrals /> : null
      }
      {
        activeTab === 0 ? <RenderReferAFriendTab ref_link={affiliate?.ref_link}/> : null
      }
    </div>
  </div>
}
function RenderReferAFriendTab({ref_link}){
  const [isSuccess, setOnSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false);
  const submit = (e)=>{
    if(e){
      e.preventDefault();
      e.stopPropagation();
    }
    setLoading(true)
    post('/refer-a-friend/friend-emails',{
      affiliate_id: getItem("gfp_refer_a_friend_affiliate_id"),
      emails: email
    }).then((data)=>{
      setLoading(false)
      setOnSuccess(true)
      setEmail('')
      setTimeout(function(){
        setOnSuccess(false)
      },10000)
    })
  }
  const {title, enter_friend_email_label, btn_title_submit, friend_email_input_info, friend_email_input_placeholder} = useSelector(selector)
  return <div className={"flex-fill d-flex align-items-center justify-content-center"}>
    <div className="text-center" style={{paddingTop:64, paddingBottom:64, maxWidth:'75%'}}>
      {
        isSuccess ?
          <ThanksMessage /> : <>
              <h1>{title}</h1>
              <CopyButton text={ref_link} title={ref_link}/>
              <p>{enter_friend_email_label}</p>
            <hr/>
              <form onSubmit={submit}>
                <FormGroup info={friend_email_input_info}>
                  <Input placeholder={friend_email_input_placeholder} type={"email"} value={email} onChange={setEmail} />
                </FormGroup>
                <StyledButton disabled={!isValidEmail(email)} title={btn_title_submit} loading={loading} onClick={submit}  />
              </form>
          </>
      }
    </div>
  </div>
}

function RenderYourReferrals(){
  const {message_no_referrals} = useSelector(selector)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  useEffect(()=>{
    setLoading(true)
    get('/refer-a-friend/referrals?affiliate_id='+getItem('gfp_refer_a_friend_affiliate_id'))
        .then((data)=>{
          setData(data.referrals)
          setLoading(false)
    })
  },[])
  return <>
  {
    loading ? <LoadingSkeleton height={32} count={5} /> : <>
      {
        data.length === 0 ? <p className={"text-center py-4"}>{message_no_referrals}</p> :    <ul className="list-group list-group-flush border-left border-right border-bottom">
          {
            data.map((i)=>{
              return       <li key={String(i.email)}  className="p-3 space-between list-group-item">
                <span>{i.email}</span>
                <span>{i.status}</span>
              </li>

            })
          }
        </ul>
      }
    </>
  }


  </>
}


function RenderTabs({activeTab, setActiveTab}){
  const {tab_refer_a_friend, tab_your_referrals} = useSelector(selector)
  return <ul className="nav nav-fill nav-tabs">
    <li className="nav-item">
      <button className={`nav-link ${activeTab === 0 ? 'active' : ''}` }
         onClick={(e)=>{
           setActiveTab(0)
         }}
         aria-current="page">{tab_refer_a_friend}</button>
    </li>
    <li className="nav-item">
      <button className={`nav-link ${activeTab === 1 ? 'active' : ''}` }
         onClick={(e)=>{
           setActiveTab(1)
         }}
        >{tab_your_referrals}</button>
    </li>
  </ul>
}

const emailValidator = new RegExp(/\S+@\S+\.\S+/);

export const isValidEmail = (email)=>{
  return email && emailValidator.test(email)
}

function Enroll({onEnrolled}){
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const onSubmit = (e)=>{
    if(e){
      e.preventDefault();
      e.stopPropagation()
    }
    setLoading(true)
    post('/refer_a_friend/login', {
      email,
      name,
    }).then((data)=>{
      setItem('gfp_refer_a_friend_customer_email', email)
      setItem('gfp_refer_a_friend_affiliate_id', data?.affiliate_id)
      setLoading(false)
      onEnrolled(true)
    })
  }

  const {title, subtitle, super_text, btn_title_next, placeholder_your_email, placeholder_your_name} = useSelector(selector)

  return <div className={"flex-fill d-flex align-items-center justify-content-center"}>
     <div className="text-center p-4" style={{maxWidth:400}}>
    <h6 className={"text-uppercase"}>{super_text}</h6>
    <h1>{title}</h1>
    <p style={{whiteSpace:'pre-line'}}>{subtitle}</p>
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <Input value={name} onChange={setName}
               type="text" name={"name"} placeholder={placeholder_your_name}/>
      </div>
    <div className="form-group">
      <Input value={email} onChange={setEmail}
             type="email" name={"email"} placeholder={placeholder_your_email}/>
    </div>
    <StyledButton onClick={onSubmit} type={"submit"} loading={loading} disabled={!isValidEmail(email)} title={btn_title_next} />
    </form>
  </div>
  </div>
}

export default ReferAFriend;
