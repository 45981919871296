import React, {useMemo} from 'react';

function ProgressBar({progress, max, current, height, text, fillColor, type}:{progress?: number, max: number, current: number, height?: number, text?: string, fillColor?: string, type?:'success' | 'warning' | 'danger' | 'primary'}) {
    const width = progress !== undefined ? progress : Math.round((current * 100)/max);
    const cls = useMemo(()=>{
        switch (type){
            case "success":
                return 'bg-success';
            case "warning":
                return 'bg-warning';
            case "danger":
                return "bg-danger";
            case "primary":
                return "bg-primary"
        }
        return ''
    },[type])

    return (
        <div className="progress" style={{height}}>
            <div className={`progress-bar ${cls}`}
                 role="progressbar"
                 style={{width:width+"%", background:fillColor}}>
                {text}
            </div>
        </div>
    );
}

export default ProgressBar;
