import {FormattedMessage} from 'react-intl';
import React from 'react';

function RenderInterval({interval_unit, interval_count}) {
    switch (interval_unit) {
        case "MONTH":
            return <FormattedMessage id={"recurring_plan_monthly"} values={{num: interval_count}}/>
        case "YEAR":
            return <FormattedMessage id={"recurring_plan_yearly"} values={{num: interval_count}}/>
        case "WEEK":
            return <FormattedMessage id={"recurring_plan_weekly"} values={{num: interval_count}}/>
        case "DAY":
            return <FormattedMessage id={"recurring_plan_daily"} values={{num: interval_count}}/>
        default:
            return null;
    }
}

export default RenderInterval
